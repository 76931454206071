/*eslint-disable */
<template>
  <div>
    <b-modal v-if="propModalView" v-model="showInterestModal" centered scrollable :title="propModalTitle" no-close-on-backdrop no-close-on-esc>
      <b-container fluid class="p-0" style="height: 500px;">
        <b-row v-if="interestCatObjList">
          <b-col md="12">
            <b-card no-body>
              <b-card-text class="p-0">
                <div
                  v-for="(interests, index) in interestObjList"
                  :key="index"
                  class="mb-3">
                  <div>
                    <span style="text-transform: capitalize;">
                      {{ interestCatObjList[index]['interest_cat_name'] }}
                    </span>
                  </div>
                  <div>
                    <span
                      v-for="(interest, index_int) in interests"
                      :key="interest.interest_id">
                      <b-badge
                        style="cursor:pointer;"
                        :style="(selectedInterestObjList && selectedInterestObjList[interest.interest_id]) ? setInterestChipStyle(interestCatObjList[index], true) : setInterestChipStyle(interestCatObjList[index], false)"
                        class="border mr-2 mb-2 font-weight-normal"
                        variant="none"
                        @click="selectInterest(interest)"
                        v-if="index_int < interestCatLimit[index]">
                        <span>{{ interest.interest_name }}</span>
                      </b-badge>
                    </span>
                    <b-badge variant="light" v-if="interests.length > interestCatLimit[index]" @click="showMoreInterests(index)" style="cursor: pointer; background-color: #EEEEEE!important" class="font-weight-normal">
                      <span>Show more</span>
                    </b-badge>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" variant="secondary" @click="closeInterestModal()">
          Skip
        </b-button>
        <b-button size="sm" variant="primary" @click="userProfileEdit()" :disabled="submitting">
          <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
          </b-spinner>
          <span v-else>Submit</span>
        </b-button>
      </template>
      <b-toast
        v-model="showToast"
        name="Toast"
        :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
        :variant="toastVariant"
        :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-modal>
    <!-- NO MODAL -->
    <!-- @todo shubham remove duplicate -->
    <template v-else>
      <b-container fluid class="p-0" style="">
        <b-row v-if="interestCatObjList">
          <b-col md="12">
            <b-card no-body>
              <b-card-text class="p-0">
                <div
                  v-for="(interests, index) in interestObjList"
                  :key="index"
                  class="mb-3">
                  <div>
                    <span style="text-transform: capitalize;">
                      {{ interestCatObjList[index]['interest_cat_name'] }}
                    </span>
                  </div>
                  <div>
                    <span
                      v-for="(interest, index_int) in interests"
                      :key="interest.interest_id">
                      <b-badge
                        style="cursor:pointer;"
                        :style="(selectedInterestObjList && selectedInterestObjList[interest.interest_id]) ? setInterestChipStyle(interestCatObjList[index], true) : setInterestChipStyle(interestCatObjList[index], false)"
                        class="border mr-2 mb-2 font-weight-normal"
                        variant="none"
                        @click="selectInterest(interest)"
                        v-if="index_int < interestCatLimit[index]">
                        <span>{{ interest.interest_name }}</span>
                      </b-badge>
                    </span>
                    <b-badge variant="light" v-if="interests.length > interestCatLimit[index]" @click="showMoreInterests(index)" style="cursor: pointer; background-color: #EEEEEE!important" class="font-weight-normal">
                      <span>Show more</span>
                    </b-badge>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
        <b-button size="sm" variant="primary" class="pull-right" @click="userProfileEdit()" :disabled="submitting">
          <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
          </b-spinner>
          <span v-else>Submit</span>
        </b-button>
    </template>
  </div>
</template>
<style>
  .active_interest {
    background-color: #51B5FD!important;
    border-color: #51B5FD!important;
  }
  .active_interest span {
    color: #FFFFFF!important;
  }
</style>
<script>
import { Interests } from "../FackApi/api/interest"
import { User } from "../FackApi/api/user"
import ApiResponse from "../Utils/apiResponse.js"

export default {
  name: "Interest",
  props: {
    propShowInterestModal: {
      default: false
    },
    propModalView: {
      default: true
    },
    propModalTitle: {
      default: "Add your Interests"
    },
    propInterestCat: {
      type: String,
      default: ""
    }
  },
  components: {
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    if (!this.propModalView) {
      this.selectedInterestObjList = {}
      await this.userInterestList(this.propInterestCat)
      await this.interestList(this.propInterestCat)
    }
  },
  data () {
    return {
      ITEM_LIMIT: 1000,
      cvCardTitle: "Interests",
      showInterestModal: false,
      interestObjList: [],
      interestCatObjList: {},
      userInterestObjList: {},
      interestCatLimit: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Interest Response",
      totalRows: 0,
      page: 1,
      userSubjects: {},
      selectedInterestObjList: null,
      submitting: false
    }
  },
  methods: {
    /**
     * interestList
     */
    async interestList (interestCat) {
      try {
        let interestObj = {
          "page": this.page
        }

        let interestListResp = await Interests.interestWithCategoryList(this, interestObj, interestCat)
        if (interestListResp.resp_status) {
          this.interestObjList = interestListResp.resp_data
          this.interestCatObjList = interestListResp.resp_data_1
          for (let intCat in this.interestCatObjList) {
            this.interestCatLimit[this.interestCatObjList[intCat].interest_cat_id] = this.ITEM_LIMIT
          }
          this.totalRows = interestListResp.resp_data.count
        }
        else {
          this.toastMsg = interestListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
    },
    /**
     * selectInterest
     */
    selectInterest (interest) {
      if (!this.selectedInterestObjList) {
        this.selectedInterestObjList = {}
      }
      if (this.selectedInterestObjList[interest.interest_id]) {
        this.$delete(this.selectedInterestObjList, interest.interest_id)
      }
      else {
        this.$set(this.selectedInterestObjList, interest.interest_id, interest)
      }
    },
    /**
     * setInterestChipStyle
     * @param interestCat
     * @param active
     * @returns {{color, "border-color": string}}
     */
    setInterestChipStyle (interestCat, active) {
      if (active) {
        return {
          "color": "#FFFFFF",
          "border-color": interestCat["interest_cat_color"] + "!important",
          "background-color": interestCat["interest_cat_color"] + "!important"
        }
      }
      return {
        "color": interestCat["interest_cat_color"],
        "border-color": interestCat["interest_cat_color"] + "!important"
      }
    },
    /**
     * userProfileEdit
     */
    async userProfileEdit () {
      this.submitting = true
      try {
        if (!this.selectedInterestObjList || (this.selectedInterestObjList && Object.keys(this.selectedInterestObjList).length < 1)) {
          this.toastMsg = "Please select at least one interest."
          this.toastVariant = "danger"
          this.showToast = true
          this.submitting = false
          return false
        }

        this.userSubjects = this.selectedInterestObjList
        let userObj = {
          "user_interest": this.selectedInterestObjList
        }

        let userProfileEditResp = await User.userProfileEdit(this, userObj)
        await ApiResponse.responseMessageDisplay(this, userProfileEditResp)

        if (userProfileEditResp && !userProfileEditResp.resp_status) {
          this.submitting = false
          return false
        }

        setTimeout(() => {
          this.submitting = false
          if (this.propModalView) {
            this.selectedInterestObjList = {}
          }
          this.closeInterestModal()
        }, 200)
      }
      catch (err) {
        console.error("Exception occurred at userProfileEdit() and Exception:", err.message)
      }
    },
    /**
     * closeInterestModal
     */
    closeInterestModal () {
      this.showInterestModal = false
      this.$emit("emitCloseInterestModal", true, this.userSubjects)
    },
    /**
     * showMoreInterests
     */
    showMoreInterests (intCatId) {
      let newLimit = this.interestCatLimit[intCatId] + 5
      this.$set(this.interestCatLimit, intCatId, newLimit)
      this.$forceUpdate()
    },
    /**
     * interestList
     */
    async userInterestList (interestCat) {
      try {
        let tempSelectedInterestObjList = null

        let userInterestListResp = await User.userInterestList(this, this.userData.user_id, interestCat)
        if (userInterestListResp.resp_status) {
          this.userInterestObjList = userInterestListResp.resp_data.interest_list

          for (let interestCatId in this.userInterestObjList) {
            let interestCat = this.userInterestObjList[interestCatId]

            for (let index in interestCat) {
              if (!tempSelectedInterestObjList) {
                tempSelectedInterestObjList = {}
              }

              tempSelectedInterestObjList[interestCat[index].interest_id] = interestCat[index]
            }
          }

          Object.assign(this.selectedInterestObjList, tempSelectedInterestObjList)
        }
      }
      catch (err) {
        console.error("Exception occurred at userInterestList() and Exception:", err.message)
      }
    }
  },
  watch: {
    async propShowInterestModal () {
      if (this.propShowInterestModal) { this.submitting = false }
      this.selectedInterestObjList = {}
      await this.userInterestList(this.propInterestCat)
      await this.interestList(this.propInterestCat)
      this.showInterestModal = true
    }
  }
}

</script>
