<template>
  <div>
    <h6 class="m-bold mt-3 pl-2">
      <span class="Heading"> Counselling Notes</span>
      <template v-if="counsellorView">
        <i class="fa-solid fa-circle-plus pull-right primary-color pointer pt-5px add_btn" v-if="!showAddNotesPrivate" @click="showAddNotesPrivate = !showAddNotesPrivate" title="Add Private Notes Visible to Only Counselor"></i>
        <i class="fa-solid fa-circle-plus pull-right warning-color pointer pt-5px add_btn mr-2" v-if="!showAddNotesPublic" @click="showAddNotesPublic = !showAddNotesPublic" title="Add Notes Visible to Student & Parent"></i>
      </template>
    </h6>
    <hr class="w-100 theme_color" style="margin-top:8px"/>

    <template v-if="counsellorView">
      <!-- Private Notes -->
      <section class="mb-5" v-show="showAddNotesPrivate">
        <SpcmNoteAdd :propPublicType="false" :propSpcmObj="propSpcmObj" @emitNoteAdd="noteAdd"></SpcmNoteAdd>
      </section><!-- Private Notes -->

      <!-- Public Notes -->
      <section v-show="showAddNotesPublic" style="margin-top: 3rem !important; margin-bottom: 5rem !important;">
        <SpcmNoteAdd :propPublicType="true" :propSpcmObj="propSpcmObj" @emitNoteAdd="noteAdd"></SpcmNoteAdd>
      </section><!-- Public Notes -->
    </template>

    <template v-if="noteList && noteList.length">
      <iq-card body-class="p-0" v-for="(note, index) of noteList" :key="index+'note'">
        <template v-slot:body>
          <div class="user-post-data p-3">
            <div class="d-flex flex-wrap">
              <div class="media-support-info ">
                <h5 class="mb-0">{{index+1}}. {{note.session_title}}&nbsp;
                  <small v-if="note.note_public_type == 1"><span class="warning-color">(Visible to Students & Parents)</span></small>
                  <small v-else><span class="primary-color">(Counsellor Notes)</span></small>
                </h5>
                <p class="mb-0 text-secondary"><small>
                  {{note.session_start_ts | dateFormatDDMMYYYYHHMMA}}
                </small></p>
              </div>

              <!-- Session Menu 3 Dots -->
              <div class="iq-card-header-toolbar d-flex align-items-center" style="padding:0">
                <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0 mr-0">
                  <template v-slot:button-content>
                    <b-link href="javascript:void(0)" class="text-secondary postThreeDotIcon"><i class="ml-0 ri-more-2-line"></i></b-link>
                  </template>
                  <a v-if="userData.user_id === note.user_id" class="dropdown-item p-3" href="javascript:void(0)">
                    <div class="d-flex align-items-top">
                      <div class="icon font-size-20"><i class="fa-solid fa-pen-to-square"></i></div>
                      <div class="data ml-2" @click="showUpdateNote=true"><h6>Edit Note</h6></div>
                    </div>
                  </a>
                  <a class="dropdown-item p-3" v-if="userData.user_role === 'USERROLE11111' || userData.user_role === 'USERROLE11118'" href="javascript:void(0)">
                    <div class="d-flex align-items-top">
                      <div class="icon font-size-20"><i class="fa-solid fa-trash"></i></div>
                      <div class="data ml-2" @click="deleteSpcmNote(index, note)"><h6>Delete Note</h6></div>
                    </div>
                  </a>
                </b-dropdown>
              </div><!-- Session Menu 3 Dots -->
            </div>
          </div>
        </template>

        <div class="user-post px-3 overflow-hidden">
          <div v-if="counsellorView && showUpdateNote" style="margin-bottom: 5rem !important;">
            <vue-editor v-model="note.note_desc"></vue-editor>
            <button class="btn btn-primary d-block pull-right add_button mt-1" @click="updateSpcmNote(note)">Update</button>
          </div>
          <div v-else class="vhtmlUlLi" v-html="note.note_desc"></div>
        </div>

        <div class="comment-area" v-if="note.note_desc">
          <!-- comment input box -->
          <div>
            <div  class="d-flex w-full">
              <b-form class="comment-text d-flex align-items-center ml-3 mr-3 mb-3 w-full rounded" style="background:#f4f4f4;" action="javascript:void(0);">
                <b-form-textarea class="text_box" v-model="note.comment" rows="1" wrap="soft" @keyup.enter="commentAdd(note)" placeholder="Write a comment..." />

                <div class="comment-attagement d-flex">
                  <b-link href="javascript:void(0);">
                    <i class="ri-send-plane-line mr-2" @click="commentAdd(note)"></i>
                  </b-link>
                </div>
              </b-form>
            </div>

            <!-- note comment -->
            <ul class="post-comments p-0 m-0" v-if="note.commentList && note.commentList.length">
              <li class="mb-2" v-for="(noteComment, index) of  note.commentList" :key="index+noteComment.id+'comment'+noteComment.note_id">
                <div class="d-flex w-full">
                  <div class="comment-data-block ml-3 w-full" >
                    <div class="d-flex flex-row  justify-content-start w-full">
                      <div class="w-full" >
                        <div v-if="noteComment.comment_edited" class="mb-4"><!-- comment edit form -->
                          <b-form class="comment-text d-flex align-items-center mt-1" action="javascript:void(0);" style="background:#f4f4f4;">
                            <b-form-textarea v-model="noteComment.note_desc" rows="1" wrap="soft" @keyup.enter="commentEdit(noteComment)" class="rounded text_box"  required />
                            <div class="comment-attagement d-flex">
                              <b-link href="javascript:void(0);">
                                <i v-b-tooltip.hover.bottomright title="Cancel" class="far fa-times-circle mr-2" @click="commentEditHideInput(noteComment)"></i>
                              </b-link>
                              <b-link href="javascript:void(0);">
                                <i v-b-tooltip.hover.bottomright title="Ok" class="fas fa-check mr-1" @click="commentEdit(noteComment)"></i>
                              </b-link>
                            </div>
                          </b-form>
                        </div><!-- comment edit form -->
                        <div v-else class="d-flex justify-content-start" ><!-- comment normal view -->
                          <div class="d-flex flex-column py-1 px-2 rounded pointer commentBox">
                            <h6>
                              {{noteComment.user_name}}
                            </h6>

                            <p v-if="noteComment.note_desc && !showCompleteComment">
                              <span class="vhtmlUlLi" v-html= "noteComment.note_desc"></span>
                              <span v-if="noteComment.note_desc && noteComment.note_desc.length > 240">{{noteComment.note_desc.substr(0, 240)}} &nbsp; <b-link href="#" @click.prevent="showMoreComment(noteComment)">See more</b-link></span>
                            </p>
                            <p v-else class="mb-5">
                              <span class="vhtmlUlLi" v-html="noteComment.note_desc"></span>
                            </p>
                          </div>

                          <div v-if="noteComment.user_id === userData.user_id" class="d-flex flex-col  justify-content-center align-items-center ml-3">
                            <b-dropdown  no-caret no-flip  size="sm" variant="light"  style="background: #fff !important;" >
                              <template #button-content>
                                <i class="fas fa-ellipsis-h" style="color:#afafaf;"></i>
                              </template>
                              <b-dropdown-item-button @click="commentEditShowInput(noteComment)" >
                                Edit
                              </b-dropdown-item-button>
                              <b-dropdown-item-button @click="commentDelete(noteComment, note, index)" >
                                Delete
                              </b-dropdown-item-button>
                            </b-dropdown>
                          </div>
                        </div><!-- comment normal view -->
                      </div>
                    </div>

                    <div v-if="!noteComment.comment_edited" class="d-flex flex-wrap align-items-center comment-activity mb-2">
                      <span>{{noteComment.created_on | formatDate}}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <p v-if="currentPage && totalPage && currentPage !== totalPage " >
              <a href="#" @click.prevent="commentLoadMore()"> View  more comment </a>
            </p>
          </div>
        </div>
      </iq-card>
    </template>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>
<script>
import { SpcmNotess } from "../FackApi/api/spcmNotes"
import ApiResponse from "../Utils/apiResponse.js"
import SpcmNoteAdd from "./SpcmNoteAdd.vue"
import { VueEditor } from "vue2-editor"

export default {
  name: "SpcmNoteList",
  props: {
    propSpcmObj: {
      default: () => {
        return {}
      }
    }
  },
  components: {
    SpcmNoteAdd,
    VueEditor
  },
  data () {
    return {
      post: {
        note_desc: "",
        created_on: new Date()
      },
      showCompleteComment: false,
      gidelogo: process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE,
      noteList: [],
      updatedCommentList: 1,
      currentPage: 10,
      totalPage: 10,
      showToast: false,
      toastTitle: "Notes Added",
      toastMsg: null,
      toastVariant: "",
      showAddNotesPrivate: false,
      showAddNotesPublic: false,
      showUpdateNote: false,
      counsellorView: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    if (this.userData.user_role == "USERROLE11118" || this.userData.user_role == "USERROLE11111") {
      this.counsellorView = true
    }
    this.spcmNoteList()
  },
  methods: {
    /**
     * noteAdd
     */
    noteAdd (event) {
      this.noteList.unshift(event)

      if (event.propPublicType == false) {
        this.showAddNotesPrivate = false
      }

      if (event.propPublicType == true) {
        this.showAddNotesPublic = false
      }
    },
    /**
     * spcmNoteList
     */
    async spcmNoteList () {
      let payload = {
        note_public_type: this.counsellorView == true ? null : 1,
        note_type: 1, // 1 for note or 2 for comment
        spcm_id: this.propSpcmObj.spcm_id
      }

      const spcmListResp = await SpcmNotess.spcmNotesList(this, payload)
      if (spcmListResp.resp_status) {
        this.noteList = spcmListResp.resp_data.data
      }
    },
    /**
     * update Spcm Note
     */
    async updateSpcmNote (note) {
      try {
        let payload = {
          note_id: note.note_id,
          spcm_id: this.propSpcmObj.spcm_id,
          session_title: note.session_title,
          note_desc: note.note_desc,
          note_type: 1,
          note_public_type: note.note_public_type ? 1 : 0,
          counsellor_id: this.propSpcmObj.counsellor_id,
          gide_user_id: this.propSpcmObj.user_id
        }

        const spcmNoteResp = await SpcmNotess.spcmNotesEdit(this, payload)
        if (spcmNoteResp.resp_status) {
          this.showUpdateNote = false
        }

        ApiResponse.responseMessageDisplay(this, spcmNoteResp)
      }
      catch (error) {
        console.error("Exception in updateSpcmNote()", error)
      }
    },
    /**
     * deleteSpcmNote
     */
    async deleteSpcmNote (i, note) {
      try {
        const spcmNoteResp = await SpcmNotess.spcmNotesDelete(this, note.note_id)
        if (spcmNoteResp.resp_status) {
          this.noteList.splice(i, 1)
        }

        ApiResponse.responseMessageDisplay(this, spcmNoteResp)
      }
      catch (error) {
        console.error("Exception in deleteSpcmNote()", error)
      }
    },
    /**
     * comment Add under notes
     */
    async commentAdd (note) {
      // Format the comment data
      note.comment = note.comment.replace(/\n\r?/g, "<br>")

      let payload = {
        notes_module_obj_id: note.note_id,
        spcm_id: this.propSpcmObj.spcm_id,
        session_title: note.session_title,
        session_id: note.session_id,
        note_desc: note.comment,
        note_public_type: 1,
        note_type: 2,
        counsellor_id: this.propSpcmObj.counsellor_id,
        gide_user_id: this.propSpcmObj.user_id
      }

      const noteCommentAddResp = await SpcmNotess.spcmNotesAdd(this, payload)
      ApiResponse.responseMessageDisplay(this, noteCommentAddResp)
      if (noteCommentAddResp.resp_status) {
        if (!note.commentList) {
          note.commentList = []
        }
        note.commentList.unshift({ ...noteCommentAddResp.resp_data, user_name: this.userData.user_name, created_on: new Date() })
        note.comment = ""
      }
    },
    /**
     * commentEditShowInput
     */
    commentEditShowInput (noteComment) {
      noteComment.comment_edited = true
      noteComment.original_note = noteComment.note
      noteComment.id += 1
    },
    /**
     * commentEditHideInput
     */
    commentEditHideInput (noteComment) {
      noteComment.note = noteComment.original_note
      noteComment.comment_edited = false
      noteComment.id += 1
    },
    /**
     * commentEdit
     */
    async commentEdit (noteComment) {
      let payload = {
        note_id: noteComment.note_id,
        note_desc: noteComment.note_desc,
        note_type: 2,
        note_public_type: this.propPublicType ? 1 : 0,
        counsellor_id: this.propSpcmObj.counsellor_id,
        gide_user_id: this.propSpcmObj.user_id
      }

      const noteCommentAddResp = await SpcmNotess.spcmNotesEdit(this, payload)
      ApiResponse.responseMessageDisplay(this, noteCommentAddResp)
      if (noteCommentAddResp.resp_status) {
        noteComment.original_note = payload.note_desc
        this.commentEditHideInput(noteComment)
      }
    },
    /**
     * postCommentShow
     */
    postCommentShow () {
      this.post.post_show_comment = true
      console.log("postCommentShow")
    },
    /**
     * commentDelete
     */
    async commentDelete (noteComment, note, index) {
      const commentDeleteResp = await SpcmNotess.spcmNotesDelete(this, noteComment.note_id)
      ApiResponse.responseMessageDisplay(this, commentDeleteResp)
      if (commentDeleteResp.resp_status) {
        note.commentList.splice(index, 1)
      }
    },
    /**
     * showMoreComment
     */
    showMoreComment () {
      this.showCompleteComment = true
    },
    /**
     * commentLoadMore
     */
    commentLoadMore () {
      try {
        this.currentPage++
      }
      catch (err) {
        console.error("Exception occurred at commentLoadMore() and Exception:", err.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.rounded{
  border-radius: 10px !important;;
}
@media screen and (max-width: 991px){
  .Heading{
    position: relative;
    left: 24px;
  }
  .add_btn{
    position: relative;
    right: 30px;
  }
}
.text_box{
  line-height: 30px !important;
}
</style>
