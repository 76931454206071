/*eslint-disable */
// import SpcmNotesModel from '../../Model/SpcmNotes'
import request from '../../Utils/curl'

let SpcmNotess = {
  /**
   * spcmNotesConsoleList
   */
  async spcmNotesConsoleList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_notes_console_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmNotesConsoleList() and Exception:',err.message)
    }
  },
    /**
   * spcmNotesList
   */
  async spcmNotesList (context, payload) {
    try{
      let post_data = new FormData();  
      if (payload) {
        for (const key in payload) {
          if (payload[key] || typeof payload[key] == "number") {
            post_data.append(key, payload[key])
          }
        }
      }

      return await request.curl(context, "spcm_notes_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmNotesList() and Exception:',err.message)
    }
  },

  /**
   * spcmNotesView
   */
  async spcmNotesView (context, spcmNotesId) {
    try {      
      let post_data = new FormData();
      post_data.append('note_id', spcmNotesId);
      return await request.curl(context, "spcm_notes_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcmNotesView() and Exception:",err.message)
    }    
  },

  /**
   * spcmNotesAdd
   */
  async spcmNotesAdd (context, spcmNotesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmNotesObj) {
        if (spcmNotesObj[key] || typeof spcmNotesObj[key] == "number") {
          post_data.append(key, spcmNotesObj[key]);
        }
      }

      return await request.curl(context, "spcm_notes_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmNotesAdd() and Exception:',err.message)
    }
  },

  /**
   * spcmNotesEdit
   */
  async spcmNotesEdit (context, spcmNotesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmNotesObj) {
        if (spcmNotesObj[key] || typeof spcmNotesObj[key] == "number") {
          post_data.append(key, spcmNotesObj[key]);
        }
      }

      return await request.curl(context, "spcm_notes_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmNotesEdit() and Exception:',err.message)
    }
  },

  /**
   * spcmNotesDelete
   */
  async spcmNotesDelete (context, spcmNotesId) {
    try{
      let post_data = new FormData();
      
      post_data.append('note_id', spcmNotesId);

      return await request.curl(context, "spcm_notes_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcmNotesDelete() and Exception:',err.message)
    }
  }
}

export {
  SpcmNotess
}
