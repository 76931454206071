<template>
  <div class="row-100 mt-3" :key="updateDom">
    <template v-if="showSessionList">
      <h6 class="m-bold pl-2">
        Sessions
        <i class="pt-5px fa-solid fa-circle-plus pull-right primary-color pointer" v-if="counsellorView" @click="showAddSession()"></i>
      </h6>
      <hr class="w-100 theme_color" style="margin-top:8px"/>
      <div class="mt-3 table_scroll">
        <div class="row w-100 mb-3 m-0">
          <template v-for="(session, index) of sessionList">
            <div class="col-12 col-sm-4 col-md-12 col-lg-12 col-xxl-12 m-0 p-0" :key="index+session.session_id+session.session_state">
              <iq-card class="seesionBoxes">
                <div class="card-body iq-card-body p-0">
                <div class="col-12">
                  <h6>Session {{index+1}}
                    <span v-if="session.state != 3 && counsellorView && currentUtcTs < session.session_end_ts" class="pointer float-right small pt-1" @click="showAddSession(session)">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </span>
                  </h6>
                </div>
                <div class="col-12">
                  <span style="display:block;"><small>
                    by {{session.counsellor_name}}<br>{{session.session_start_ts | dateFormatDDMMYYYYHHMMA}}
                  </small></span>
                </div>
                <div class="col-12 mt-3">
                <b-button v-if="session.session_state == 0 && studentView" variant="primary" class="w-100 btn_confirm" @click="payNow(session)">
                    Pay Now
                </b-button>
                <b-button v-if="session.session_state == 0 && counsellorView && propSpcmObj.spcm_total_session <= 2" :disabled="true" variant="primary" class="w-100 btn_confirm">
                    Waiting for payment
                </b-button>
                <b-button v-else-if="counsellorView && session.session_state == 1" variant="primary" class="w-100 btn_confirm" @click="sessionStateChange(session, 'CONFIRM_MEETING')">
                    Confirm Meeting
                </b-button>
                <b-button v-else-if="studentView && session.session_state == 1" :disabled="true" variant="primary" class="w-100 btn_confirm">
                    Waiting
                </b-button>
                <b-button v-else-if="session.session_state == 2 && currentUtcTs < session.session_end_ts" variant="primary" class="w-100 btn_confirm" @click="joinMeeting(session)">
                    Join Now
                </b-button>
                <!-- <b-button v-else-if="session.session_state == 3 || currentUtcTs > session.session_end_ts" :disabled="true" variant="primary" class="w-100 btn_confirm">
                    Session Completed
                </b-button> -->
                </div>
                </div>
              </iq-card>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <ValidationObserver ref="admissionForm">
        <form class="mt-3 iq-card p-3" @submit.prevent="onSubmit">
            <div class="form-row">
            <div class="col-md-12 mb-3" v-if="counsellorView">
                <label for="session_hosting_platform s-heading">Hosting PlatForm</label>
                <select
                v-model="vmSessionForm.session_hosting_platform"
                class="form-control"
                :disabled="!counsellorView"
                id="session_hosting_platform"
                >
                <option
                    v-for="(platform, index) in SPCMSessionHostingPlatForm"
                    :key="index"
                    :value="platform"
                >
                    {{ platform }}
                </option>
                </select>
            </div>
            <template v-if="vmSessionForm.session_hosting_platform != SPCMSessionHostingPlatForm.ZOOM">
                <div class="col-md-12 mb-3">
                    <label for="validation_chooseDate">Seesion Meeting Weblink</label>
                    <input
                    v-model="vmSessionForm.session_weblink"
                    type="text"
                    class="form-control"
                    id="validation_session_weblink"
                    />
                </div>
            </template>
            <div class="col-md-6 mb-3" v-if="!counsellorView">
              <label for="validation_chooseDate">Counsellor</label>
                <multiselect
                  v-model="vmSessionForm['counsellor']"
                  :options="cvCounsellorOptions"
                  placeholder="Search"
                  label="user_name"
                  @select="getAvailableSlots"
                  track-by="user_name">
              </multiselect>
            </div>
            <div class="col-md-6 mt-5" v-if="vmSessionForm['counsellor'] && !counsellorView">
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div class="iq-card-body">
                    <div class="iq-badges text-left">
                      <div class="badges-icon">
                        <img class="avatar-80" style="border-radius:50%" v-if="vmSessionForm['counsellor'].user_image" :src="vmSessionForm['counsellor'].user_image" alt="">
                        <div v-else class="avatar-80" style="text-align: center; background-color: #EEEEEE;">
                          <span style="margin: auto; font-size: 50px;">{{vmSessionForm['counsellor'].user_name.slice(0,1)}}</span>
                        </div>
                      </div>
                      <h5 class="mb-2">{{vmSessionForm['counsellor'].user_name}}</h5>
                      <p>{{vmSessionForm['counsellor'].user_desc}}</p>
                      <span class="text-uppercase">
                        Price: {{vmSessionForm['counsellor'].subs_plan_price}} | Gide Plus Price: {{vmSessionForm['counsellor'].subs_plan_price_gideplus}}
                      </span>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label for="validation_chooseDate">Choose Slot</label>
              <multiselect
                v-model="vmSessionForm.slot"
                class=""
                :options="availableSlotList"
                :custom-label="sessionCustomLabel"
                id="validation_chooseDate">
              </multiselect>
            </div>
            <div class="col-md-12 mb-3">
              <b-button size="sm" variant="primary" class="pull-right primary mb-3 ml-1" v-if="counsellorView" @click="showSessionListFn()">
                Cancel
              </b-button>
              <b-button size="sm" variant="primary" class="pull-right primary mb-3" @click="addSession()">
                {{vmSessionForm.session_id ? 'Edit' : 'Add'}}
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template v-if="showPaymentModal && modName && counsellorId">
      <b-modal v-model="showPaymentModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true">
        <!-- Not passing the propRedirect because the component is opened in modal  -->
        <Paygw :propModuleType="modName" :propModuleName="modName" :propSubModuleName="subModuleName" :propModuleObjId="counsellorId" @emitPaymentStatus="emitPaymentStatus" :propSrc="modName" :propSrcId="newSessionId"/>
        <template #modal-footer="">
          <b-button size="sm" class="pull-left" style="opacity: 0;">
          </b-button>
        </template>
      </b-modal>
    </template>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>
<style lang="scss" scoped>
  .seesionBoxes {
    width: 100%;
    padding: 15px 5px;
    text-align: left;
    .paidUnpaidLabel {
      text-align: center;
      display: block;
      position: relative;
      top: -10px;
    }
  }
  .btn_confirm{
    display: block;
    margin: auto;
    font-weight: 500;
  }
  @media screen and (max-width: 991px){
    .fa-circle-plus{
      padding-right:35px;
    }
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { SpcmCalenders } from "../FackApi/api/SpcmCalender.js"
import { SpcmSessions } from "../FackApi/api/spcmSession.js"
import { Subscriptions } from "../FackApi/api/Subscription/subscription.js"
import ApiResponse from "../Utils/apiResponse.js"
import Paygw from "../views/Gide/Paygw/Paygw.vue"
import SPCMSessionHostingPlatForm from "../FackApi/json/SPCMSessionHostingPlatForm.json"
import moment from "moment"
import { EventBus } from "../main.js"
import { User } from "../FackApi/api/user.js"
import Multiselect from "vue-multiselect"
import getEventHostingPlatformByRole from "../FackApi/json/EventHostingPlatforms"

export default {
  name: "SpcmSessionAddEdit",
  components: {
    Paygw,
    Multiselect
  },
  props: {
    propSpcmId: {
      default: null
    },
    propSessionId: {
      default: null
    },
    propSpcmObj: {
      default: null
    },
    propViewList: {
      default: false
    },
    propStudentData: {
      default: null
    }
  },
  data () {
    return {
      vmSessionForm: {
        session_hosting_platform: SPCMSessionHostingPlatForm.ZOOM
      },
      availableSlotList: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      showPaymentModal: false,
      toastTitle: "Counselling Session",
      modName: "counselling", // Mapped to the Table modules. Used to chheck if this module is used in subscription or not
      subModuleName: null,
      SPCMSessionHostingPlatForm: SPCMSessionHostingPlatForm,
      counsellorView: false,
      studentView: false,
      showSessionList: false,
      sessionList: [],
      currentUtcTs: moment().utc().valueOf() / 1000,
      newSessionId: null,
      updateDom: 0,
      cvCounsellorOptions: [],
      counsellorId: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    if (this.userData.user_role == "USERROLE11118" || this.userData.user_role == "USERROLE11111") {
      this.counsellorView = true
    }
    else if (this.userData.user_role == "USERROLE11114") {
      this.studentView = true
      this.getCounsellorList()
    }

    if (this.propViewList) {
      this.showSessionList = true
    }

    if (this.propSpcmObj && this.propSpcmObj.spcm_total_session < 2) {
      this.subModuleName = this.propSpcmObj.spcm_total_session == 1 ? `session_two` : "session_one" // take next session plan
      this.subscriptionModRuleAvailableCheck(this.subModuleName)
    }
    if (this.propSpcmObj && this.propSpcmObj.spcm_total_session >= 0) {
      this.getSessionList()
    }
  },
  methods: {
    /* eslint-disable */
    sessionCustomLabel ({ slot_start, slot_end }) {
      return `${slot_start} - ${slot_end}`
    },
    /* eslint-enable */
    /**
     * getCounsellorList
     */
    async getCounsellorList () {
      const payload = {
        user_role: "USERROLE11118" // counsellor
      }
      const apiResp = await User.userListSearch(this, payload)
      if (apiResp.resp_status) {
        this.cvCounsellorOptions = apiResp.resp_data.data
      }
    },
    /**
     * showSessionList
     */
    showSessionListFn () {
      this.showSessionList = true
      this.vmSessionForm = {}
    },
    /**
     * showAddSession
     */
    showAddSession (session) {
      let counsellor = {
        counsellor_id: this.propSpcmObj.counsellor_id,
        user_id: this.propSpcmObj.counsellor_id
      }
      if (session && session.session_id) {
        this.vmSessionForm = { ...session }
      }

      this.vmSessionForm.counsellor = counsellor
      this.getAvailableSlots(counsellor)
      this.showSessionList = false
    },
    /**
     * getSessionList
     */
    async getSessionList () {
      try {
        const sessionListApiResp = await SpcmSessions.spcmSessionList(this, { spcm_id: this.propSpcmId })
        if (sessionListApiResp.resp_status) {
          this.sessionList = [ ...sessionListApiResp.resp_data.data ]
        }
        else {
          await this.spcmSessionAdd()
        }
        EventBus.$emit("sessionList", this.sessionList)
        this.updateDom += 1
      }
      catch (error) {
        console.error("Exception in getSessionList()", error)
      }
    },
    /**
     * spcmSessionAdd
     */
    async spcmSessionAdd () {
      // Add a spcm session
      const spcmAddResp = {
        user_id: this.propSpcmObj.counsellor_id,
        participant_id: this.propSpcmObj.user_id,
        spcm_id: this.propSpcmObj.spcm_id,
        session_title: "session 1",
        session_hosting_platform: getEventHostingPlatformByRole()["OTHER"],
        counsellor_id: this.propSpcmObj.counsellor_id,
        session_start_ts: moment().unix(),
        session_end_ts: moment.unix() + 3600,
        session_state: 0 // not paid Yet
      }
      const spcmSessionAddResp = await SpcmSessions.spcmSessionAdd(this, spcmAddResp)
      if (spcmSessionAddResp.resp_status) {
        this.getSessionList()
      }
    },
    /**
     * emitPaymentStatus
     */
    emitPaymentStatus (event) {
      this.showPaymentModal = false
      if (event && (event.includes("Captured") || event.includes("Success"))) {
        if (this.$route.query.module_name) {
          this.$router.push(`/spcm/`)
        }
        else {
          window.location.reload() // @tobe remove
        }
      }
    },
    /**
     * getAvailableSlots
     */
    async getAvailableSlots (counsellor) {
      this.availableSlotList = []
      let payload = {
        counsellor_id: counsellor.user_id
      }
      this.counsellorId = counsellor.user_id

      const sessionListApiResp = await SpcmCalenders.spcmCalendarList(this, payload)
      if (sessionListApiResp.resp_status) {
        this.availableSlotList = sessionListApiResp.resp_data.data

        if (this.vmSessionForm.slot && typeof this.vmSessionForm.slot == "number") {
          const findIndex = this.availableSlotList.findIndex(ele => ele.slot_start_ts == this.vmSessionForm.slot)
          if (findIndex >= 0) {
            this.vmSessionForm.slot = this.availableSlotList[findIndex]
          }
        }
      }
      else {
        this.showToast = true
        this.toastVariant = "warning"
        this.toastMsg = "Looks like no calendar dates are available"
      }
    },
    /**
     * subscriptionModRuleAvailableCheck
     */
    async subscriptionModRuleAvailableCheck (subModuleName) {
      try {
        const ruleAvailableResp = await Subscriptions.subscriptionModRuleAvailableCheck(this, { mod_name: this.modName, sub_mod_name: subModuleName })
        if (ruleAvailableResp.resp_status) {
          this.modAvailable = ruleAvailableResp.resp_data.data
        }
      }
      catch (error) {
        console.error("subscriptionModRuleAvailableCheck Exception", error)
      }
    },
    /**
     * joinMeeting
     */
    async joinMeeting (session) {
      window.open(session.session_weblink, "_blank")
    },
    /**
     * addSession
     */
    async addSession () {
      let addSessionApi = true

      if (this.vmSessionForm.counsellor && this.vmSessionForm.counsellor.user_id) {
        // Session is not mandatory, so if a session is not added, create a session with the current timestampS
        if (!this.vmSessionForm.slot) {
          this.vmSessionForm.slot = {
            spcm_calendar_id: "SPCM" + Math.floor(100000 + Math.random() * 900000), // Generating a random spcm_calendar_id
            slot_start_ts: moment().unix(), // setting the slot start time to current timestamp
            slot_duration: 3600000 // default duration of 1hr
          }
        }

        let payload = {
          session_id: this.vmSessionForm.session_id,
          session_title: `Session ${this.sessionList.length + 1}`,
          spcm_id: this.propSpcmId,
          session_start_ts: this.vmSessionForm.slot.slot_start_ts,
          session_end_ts: Number(this.vmSessionForm.slot.slot_start_ts) + this.vmSessionForm.slot.slot_duration,
          session_hosting_platform: this.vmSessionForm.session_hosting_platform,
          session_weblink: this.vmSessionForm.session_weblink,
          counsellor_id: this.vmSessionForm.counsellor.user_id,
          spcm_calendar_id: this.vmSessionForm.slot.spcm_calendar_id,
          session_state: null,
          user_email: this.propStudentData.user_email,
          user_id: this.vmSessionForm.counsellor.user_id,
          participant_id: this.propStudentData.user_id
        }

        let apiResp = null
        if (this.counsellorView && this.vmSessionForm.session_id) {
          addSessionApi = false
          apiResp = await SpcmSessions.spcmSessionEdit(this, payload)
        }
        else {
          if (this.propSpcmObj.spcm_total_session < 2) {
            payload.session_state = 0
          }
          else {
            payload.session_state = 2 // Sessionn confirmed
          }
          addSessionApi = true
          apiResp = await SpcmSessions.spcmSessionAdd(this, payload)
        }

        ApiResponse.responseMessageDisplay(this, apiResp)

        if (apiResp.resp_status) {
          this.showSessionList = true
          this.getSessionList()
        }
        else if (apiResp.resp_code === "ERR_SUBS_PLAN_PURCHASE_REQUIRED" || apiResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER") {
          this.showPaymentModal = true
          this.showSessionList = true
          this.newSessionId = apiResp.resp_data.session_id
          return
        }

        if (addSessionApi) {
          this.$emit("emitSessionUpdated", apiResp.resp_data)
          this.sessionList.push(apiResp.resp_data)
          this.updateDom += 1
        }
      }
      else {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Counsellor is not selected for the session"
      }
    },
    /**
     * sessionStateChange
     */
    async sessionStateChange (session, sessionState) {
      try {
        let payload = {
          session_id: session.session_id,
          session_state: null,
          counsellor_id: this.counsellorView ? this.propSpcmObj.counsellor_id : null
        }

        switch (sessionState) {
          case "CONFIRM_MEETING":
            payload.session_state = 2 // Meeting Confirmed
            break
          default:
            break
        }
        const apiResp = await SpcmSessions.spcmSessionEdit(this, payload)
        ApiResponse.responseMessageDisplay(this, apiResp)
        if (apiResp.resp_status) {
          this.showSessionList = this.propViewList
          session.session_state = payload.session_state
        }
      }
      catch (error) {
        console.error("Exception in sessionStateChange", error)
      }
    },
    /**
     * payNow
     */
    async payNow (session) {
      this.subModuleName = this.sessionList.length > 1 ? "session_two" : "session_one"
      this.counsellorId = this.propSpcmObj.counsellor_id

      let payload = {
        spcm_id: this.propSpcmId,
        session_state: 1,
        session_id: session.session_id,
        counsellor_id: this.counsellorId
      }
      const apiResp = await SpcmSessions.spcmSessionEdit(this, payload)
      ApiResponse.responseMessageDisplay(this, apiResp)
      if (apiResp.resp_status) {
        this.showSessionList = true
        session.session_state = 1
      }
      else if (apiResp.resp_code === "ERR_SUBS_PLAN_PURCHASE_REQUIRED" || apiResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER") {
        this.showPaymentModal = true
        this.showSessionList = true
        this.newSessionId = session.session_id
      }
    }
  }
}
</script>
